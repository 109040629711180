html,
body {
  margin: 0;
  padding: 0;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 16px !important;
  /* overflow: scroll !important; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08) !important;
}

div .page__background {
  background-color: #67cce0;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

#root {
  display: flex;
  height: 100%;
  width: 1024px;
  margin: auto;
  position: relative;
  background-color: #fafafa;
}

@media screen and (max-width: 1024px) {
  #root {
    width: 100%;
    margin: 0;
  }
}

button {
  font-family: Lato, Helvetica, Arial, sans-serif !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.dimmed {
  position: fixed;
  content: ' ';
  z-index: 10;
  display: block;
  height: 100%;
  max-width: 1024px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  color: #222;
}
